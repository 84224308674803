import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {MantineProvider, Text} from '@mantine/core';
import {Authorize} from "./views/authorize";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Notifications} from '@mantine/notifications';
import {Register} from "./views/register";
import {RegisterSuccess} from "./views/register_success";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <MantineProvider
            theme={{
                loader: "dots",
                defaultRadius: "sm"
            }}
            withGlobalStyles withNormalizeCSS>
            <Notifications
                position={"bottom-center"}
                limit={3}/>

            <BrowserRouter>
                <Routes>
                    <Route path="/authorize" element={<Authorize/>}/>
                    <Route path="/register/success" element={<RegisterSuccess/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="*" element={<>
                        404 Not found
                    </>}/>
                </Routes>
            </BrowserRouter>

        </MantineProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
