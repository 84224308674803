import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {Background} from "../components/background";
import {Paper, Stack, Text, Title, useMantineTheme} from "@mantine/core";


export const RegisterSuccess = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const theme = useMantineTheme()

    useEffect(() => {
        setTimeout(() => {
           navigate("/authorize" + location.search)
        }, 5000)
    }, [])


    return <Background>


        <Paper maw={520} shadow={"md"} p={36}>

            <Stack>
                <Title size={18} color={theme.primaryColor}>
                    Registration successful
                </Title>
                <Text size={14}>
                    An email with a link to activate your account has been sent. You will be brought back to the login screen shortly.
                </Text>
            </Stack>

        </Paper>


    </Background>


}