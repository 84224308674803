import React, {useEffect, useRef} from "react";
import {Center, Notification, Text, Image, Stack, Button, TextInput, Paper, Flex, Divider, useMantineTheme, LoadingOverlay, Box} from "@mantine/core";
import {useForm} from '@mantine/form';
import {useDisclosure} from '@mantine/hooks';
import {IconLockOff} from '@tabler/icons-react';
import {notifications} from '@mantine/notifications';

import {useCallback} from "react";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Background} from "../components/background";
import {useOAuthParameters} from "../utils/oauth_hook";
import {OAuthError} from "../components/oauth_error";
import {GoogleSignInButton} from "../components/google_sign_in_button";

// TODO mandatory OAuth paramaeters (clientId, redirect_uri)

export const Authorize: React.FC<{}> = (props) => {

    const parameters = useOAuthParameters()
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useMantineTheme()
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email address'),
            password: (value) => (value.length < 6 ? 'Must be at least 6 characters long' : null),
        },
    });
    const [loading, setLoading] = useState(false)

    const login = useCallback((email: string, password: string) => {
        setLoading(true)
        fetch(process.env.REACT_APP_ACCURAS_OAUTH_HOST + "/authenticate", {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
                email: email,
                password: password,
                redirect_uri: parameters!.redirect_uri,
                client_id: parameters!.client_id,
                state: parameters!.state,
                scope: parameters!.scope,
                response_type: parameters!.response_type
            }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => {
                        console.log("error built", text)
                        throw new Error(text)
                    })
                } else {
                    return response.text();
                }
            })
            .then(response => {
                // @ts-ignore
                window.location = parameters!.redirect_uri + "?code=" + response
            })
            .catch(error => {

                console.log("error caught", JSON.stringify(error))

                notifications.show(
                    {
                        "title": "Authentication failed",
                        "message": error.message,
                        autoClose: 2500,
                        color: 'red',
                        icon: <IconLockOff size={18}/>,
                    }
                )
            })
            .finally(() => setLoading(false))
    }, [parameters])


    return <Background>


        <Paper w={320} p={36} shadow={"md"} pos={"relative"}>
            <Box pos="absolute" top={0} left={0} right={0} bottom={0}>
                <LoadingOverlay visible={loading} overlayBlur={2}/>
            </Box>

            <Flex align={"stretch"} direction={"column"} justify={"center"}>

                <Image src={"accuras_logo.png"} pr={24} pl={24} mb={24}/>
                <form onSubmit={form.onSubmit((values) => {

                    login(values.email, values.password)

                })}>
                    <TextInput
                        {...form.getInputProps('email')}
                        type={"email"}
                        placeholder="E-mail address"
                        label="E-Mail"/>

                    <TextInput
                        {...form.getInputProps('password')}
                        mt={12}
                        type={"password"}
                        placeholder="Your password"
                        label="Password"/>


                    <Flex
                        align={"stretch"} justify={"space-between"} mt={24} mb={24}>


                        <Flex direction={"column"} justify={"space-between"} align={"start"} sx={{}}>

                            <Button
                                variant={"white"}
                                color={"dark"}
                                opacity={0.5}
                                h={16}
                                p={0}
                                sx={{
                                    fontSize: 12,
                                    cursor: "pointer",
                                    userSelect: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    },
                                    "&:active": {
                                        opacity: 0.75,
                                    }
                                }}>
                                Forgot password?
                            </Button>
                            <Button
                                p={0}
                                variant={"white"}
                                onClick={() => {
                                    console.log("clicking")
                                    navigate('/register' + location.search);
                                }}
                                color={theme.primaryColor}
                                sx={{
                                    fontSize: 12,
                                    cursor: "pointer",
                                    userSelect: "none",
                                    fontWeight: "bold",
                                    opacity: 0.999,
                                    "&:hover": {
                                        textDecoration: "underline"
                                    },
                                    "&:active": {
                                        opacity: 1,
                                    }
                                }}>
                                Sign-up
                            </Button>

                        </Flex>


                        <Button
                            type={"submit"}
                            uppercase={true}>
                            Login
                        </Button>
                    </Flex>


                </form>
                <Divider mb={24} opacity={0.2}/>

                <GoogleSignInButton/>

            </Flex>


        </Paper>


    </Background>
}